
import axios from 'axios';
export default {
    
        async createPurchases(params)  {
            return await axios.post(`purchases/create` , params)
        },
        async createPurchasesList(params)  {
            return await axios.post(`purchases/create/list` , params)
        },
        async updatePurchasesColumn(column , value , data)  {
            return await axios.put(`purchases/update_list?${column}=${value}` , data)
        },
        async deletePurchasesList(list)  {
            return await axios.delete(`purchases/delete_list` , {
                data: {
                    list:list
                }
            })
        },
        async reportPurchases(column , value)  {
            return await axios.get(`purchases/report?${column}=${value}`)
        },
        async getAllPurchases()  {
            return await axios.get(`purchases/all`)
        },
        async getOnePurchases(purchase_id)  {
            return await axios.get(`purchases/all/${purchase_id}`)
        },
        async getPurchasesByColumn(column , value)  {
            return await axios.get(`purchases/filter?column=${column}&value=${value}`)
        },
        async deletePurchases(purchase_id)  {
            return await axios.delete(`purchases/delete/${purchase_id}`)
        },
        async updatePurchases(purchase_id , params)  {
            return await axios.put(`purchases/update/${purchase_id}` , params)
        },
}
        