
<template>
	<div>
		<v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
		<v-container v-else class="my-2">
			<form @submit.prevent="addPurchases" autocomplete="off">
				<v-layout row wrap>

					<v-flex xs12 lg2 xl2 md3 sm4>
						<v-text-field outlined v-model="purchases.purchase_date" type="date" :label="$store.getters.language.data.purchases.purchase_date" dense class="mx-1" filled required @change="readPurchases">
						</v-text-field>
					</v-flex>

					<v-flex xs12 lg2 xl2 md3 sm4>
						<v-select outlined class="mx-1" clearable :items="accounts" v-model="purchases.account_id" dense filled item-text="account_name" item-value="account_id" :return-object="false" :label="$store.getters.language.data.accounts.account_name">
						</v-select>
					</v-flex>

					<v-flex xs12 lg2 xl2 md3 sm4>
						<v-autocomplete outlined class="mx-1" clearable :items="items" v-model="purchases.item_id" dense filled item-text="item_name" item-value="item_id" :return-object="false" :label="$store.getters.language.data.items.item_name">
						</v-autocomplete>
					</v-flex>

					<v-flex xs12 lg2 xl2 md3 sm4>
						<v-text-field outlined v-model="purchases.purchase_price" type="text" :label="$store.getters.language.data.purchases.purchase_price" dense class="mx-1" filled required>
						</v-text-field>
					</v-flex>

					<v-flex xs12 lg2 xl2 md3 sm4>
						<v-text-field outlined v-model="purchases.purchase_qty" type="text" :label="$store.getters.language.data.purchases.purchase_qty" dense class="mx-1" filled required>
						</v-text-field>
					</v-flex>

					<v-flex xs12 lg2 xl2 md3 sm4>
						<v-text-field outlined v-model="purchases.purchase_expire_date" type="date" :label="$store.getters.language.data.purchases.purchase_expire_date" dense class="mx-1" filled>
						</v-text-field>
					</v-flex>

					<v-flex xs12 lg2 xl2 md3 sm4>
						<v-text-field outlined v-model="purchases.purchase_note" type="textarea" :label="$store.getters.language.data.purchases.purchase_note" dense class="mx-1" filled>
						</v-text-field>
					</v-flex>
					<v-flex xs12 lg2 xl2 md2 sm4>
						<v-btn color="primary" :loading="loading_btn" type="submit">{{$store.getters.language.data.purchases.add_btn}}</v-btn>
					</v-flex>
				</v-layout>
			</form>
			<v-layout row wrap mt-5>
				<v-flex xs12>
					<v-card>
						<v-card-text>
							<v-data-table :headers="headers" v-model="selected_rows" :search="search" :items="rows" class="elevation-0" item-key="purchase_id">
								<template v-slot:[`item.purchase_id`]="{ item }">
									<div>
										<v-btn icon :to="'/purchases-list/'+item.purchase_id" color="teal" class="mx-1">
											<v-icon> mdi-pencil-outline </v-icon>
										</v-btn>
										<v-btn color="error" icon class="mx-1" @click="selectPurchases(item)">
											<v-icon> mdi-delete-outline </v-icon>
										</v-btn>
									</div>
								</template>
								<template v-slot:[`item.purchase_date`]="{ item }">
									<div>
										{{ new Date(item.purchase_date).toISOString().split('T')[0] }}
									</div>
								</template>
								<template v-slot:[`item.purchase_expire_date`]="{ item }">
									<div>
										{{ item.purchase_expire_date ? new Date(item.purchase_expire_date).toISOString().split('T')[0] : '' }}
									</div>
								</template>
							</v-data-table>
						</v-card-text>

						<v-card-actions v-if="selected_rows.length > 0">
							<v-btn color="error" @click="deletePurchasesList">{{$store.getters.language.data.purchases.delete_list_btn}}</v-btn>
						</v-card-actions>

					</v-card>
				</v-flex>
			</v-layout>
		</v-container>
		<v-dialog v-model="delete_dialog" persistent max-width="400">
			<v-card>
				<v-card-title>
					{{$store.getters.language.data.purchases.delete_question}}
				</v-card-title>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text class="elevation-0" @click="delete_dialog = false">
						{{$store.getters.language.data.purchases.cancel_btn}}
					</v-btn>
					<v-btn color="error" class="elevation-0" @click="deletePurchases(selected_purchases.purchase_id)">
						{{$store.getters.language.data.purchases.yes_btn}}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-snackbar v-model="snackbar.value" :color="snackbar.color">
			{{snackbar.text}}
		</v-snackbar>
	</div>
</template>
<script>
	import requests from './../../requests/purchases.request.js'
	import moment from 'moment'
	export default {
		data() {
			return {
				purchases: {
					purchase_qty: 0,
					purchase_price: 0,
				},

				search: '',
				loading: true,
				loading_btn: false,
				selected_rows: [],
				snackbar: {
					value: false,
					text: '',
					color: ''
				},
				rows: [],
				selected_purchases: {},
				delete_dialog: false,
				headers: [

					{
						text: this.$store.getters.language.data.items.item_name,
						align: 'start',
						sortable: true,
						value: 'item_name',
					},
					{
						text: this.$store.getters.language.data.purchases.purchase_price,
						align: 'start',
						sortable: true,
						value: 'purchase_price',
					},
					{
						text: this.$store.getters.language.data.purchases.purchase_qty,
						align: 'start',
						sortable: true,
						value: 'purchase_qty',
					},
					{
						text: this.$store.getters.language.data.purchases.purchase_date,
						align: 'start',
						sortable: true,
						value: 'purchase_date',
					},
					{
						text: this.$store.getters.language.data.accounts.account_name,
						align: 'start',
						sortable: true,
						value: 'account_name',
					},
					{
						text: this.$store.getters.language.data.users.user_name,
						align: 'start',
						sortable: true,
						value: 'user_name',
					},
					{
						text: this.$store.getters.language.data.purchases.purchase_expire_date,
						align: 'start',
						sortable: true,
						value: 'purchase_expire_date',
					}, {
						text: this.$store.getters.language.data.purchases.purchase_note,
						align: 'start',
						sortable: true,
						value: 'purchase_note',
					},
					{
						text: this.$store.getters.language.data.actions,
						align: 'start',
						sortable: true,
						value: 'purchase_id',
					}
				],
			}
		},
		computed: {

			accounts() {
				return this.$store.getters.accounts_list
			},

			users() {
				return this.$store.getters.users_list
			},

			items() {
				return this.$store.getters.items_list
			},
			auth() {
				return this.$store.getters.user
			}


		},
		mounted() {
			this.purchases.purchase_date = new Date().toISOString().split('T')[0]
			this.readPurchases();
			this.purchases.account_id = this.accounts.find(obj => obj.account_is_default == 'true').account_id

		},
		methods: {
			
			addPurchases() {
				this.loading_btn = true
				this.purchases.user_id = this.auth.user_id
				requests.createPurchases(this.purchases).then(r => {
					if (r.status == 200) {
						this.purchases = {
							purchase_qty: 0,
							purchase_price: 0,
							account_id: this.accounts.find(obj => obj.account_is_default == 'true').account_id
						}
						this.rows.push(
							r.data.new_data
						)
						this.purchases.purchase_date = new Date(r.data.new_data.purchase_date).toISOString().split('T')[0]

						this.snackbar = {
							value: true,
							text: 'Purchases Added',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to add Purchases',
							color: 'error'
						}
					}
				})
					.finally(() => {
						this.loading_btn = false
					})

			},
			deletePurchases(purchase_id) {
				requests.deletePurchases(purchase_id).then(r => {
					this.delete_dialog = false
					if (r.status == 200) {
						this.rows = this.rows.filter(f => {
							return f.purchase_id != purchase_id
						})
						this.snackbar = {
							value: true,
							text: 'Purchases Deleted',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Delete Faild',
							color: 'error'
						}
					}
				})
			},

			deletePurchasesList() {
				let ids = this.selected_rows.map(m => m.purchase_id)
				requests.deletePurchasesList(ids).then(r => {
					if (r.status == 200) {
						this.rows = this.rows.filter(f => {
							return ids.indexOf(f.purchase_id) == -1
						})
						this.snackbar = {
							value: true,
							text: this.selected_rows.length + ' Purchases Deleted',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Delete Faild',
							color: 'error'
						}
					}
				})
			},

			readPurchases() {
				this.loading = true
				requests.reportPurchases(`store_id=${this.$store.getters.store.store_id}&purchase_date=${this.purchases.purchase_date}`).then(r => {
					if (r.status == 200) {
						this.rows = r.data
						this.loading = false
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to read Purchases',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to read Purchases',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading = false
					})
			},
			selectPurchases(i) {
				this.selected_purchases = i
				this.delete_dialog = true
			},
		},
	}
</script>
                    